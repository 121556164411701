import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {MainText} from "../components/MainComponents";
import Helmet from "react-helmet";
import styled from "styled-components";

const AbsolventCompanies = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    > img {
        margin: 2rem;
        height: 10rem;
    }

    @media (max-width: 1024px) {
        > img {
            margin: 2rem;
            height: 8rem;
        }
    }

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(4, 1fr);
        grid-row-gap: 2rem;
        justify-items: center;
        margin: 2rem 0;

        > img {
            margin: 0.5rem;
            width: auto;
            height: auto;
        }
    }

`;

export const AboutPageENTemplate = ({ image, title, heading, description, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
   <div className="">
       <div
           className="full-width-image margin-top-0"
           style={{
               backgroundImage: `url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )`,
               backgroundPosition: `top left`,
               backgroundSize: "1920px",
               height: "350px",
           }}
       >
           <div
               style={{
                   display: 'flex',
                   height: '150px',
                   lineHeight: '1',
                   justifyContent: 'space-around',
                   alignItems: 'left',
                   flexDirection: 'column',
               }}
           >
               <MainText>{title}</MainText>
           </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
            <p>{description}</p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
                <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
          <div className="columns">
              <div className="column is-10 is-offset-1">
                  <AbsolventCompanies>
                      <img src={"/img/pages/oscp.png"} alt="OSCP"/>
                      <img src={"/img/pages/burp_suite_pracititioner.png"} alt="Burp Suite Certified Practitioner"/>
                      <img src={"/img/pages/rhcsa.jpg"} alt="Red Hat Certified System Administrator"/>
                      <img src={"/img/pages/rhce.jpg"} alt="Red Hat Certified Engineer"/>
                      <img src={"/img/pages/google_cloud_1.png"} alt="Google Cloud Certification"/>
                      <img src={"/img/pages/google_cloud_2.png"} alt="Google Cloud Certification"/>
                  </AbsolventCompanies>
              </div>
          </div>
      </div>
    </section>
   </div>
  )
}

AboutPageENTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPageEN = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet titleTemplate={frontmatter.title}>
        {/* General tags */}
        <title>{frontmatter.title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="bezpečnost, it security, it bezpečnost" />
      </Helmet>
      <AboutPageENTemplate
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        content={post.html}
      />
      <div style={{marginBottom: "100px"}}/>
    </Layout>
  )
}

AboutPageEN.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPageEN

export const aboutPageENQuery = graphql`
  query AboutPageEN($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
      }
    }
  }
`
